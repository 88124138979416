// extracted by mini-css-extract-plugin
export var container = "c_b";
export var dice = "c_g";
export var face01 = "c_j";
export var face02 = "c_x";
export var face03 = "c_D";
export var face04 = "c_m";
export var face05 = "c_y";
export var face06 = "c_k";
export var face07 = "c_F";
export var face08 = "c_l";
export var face09 = "c_w";
export var face10 = "c_n";
export var face11 = "c_r";
export var face12 = "c_B";
export var face13 = "c_v";
export var face14 = "c_t";
export var face15 = "c_G";
export var face16 = "c_q";
export var face17 = "c_z";
export var face18 = "c_p";
export var face19 = "c_C";
export var face20 = "c_s";
export var roll = "c_h";